<template>
  <div>
    <b-row>
      <b-col>
        <h2>Case {{ actionObj.CaseFileNumber }}</h2>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col>
        <router-link
          :to="'/cases/edit-action/' + actionObj.ActionID"
        >
          <h2>Action {{ actionObj.ActionFileNumber }}</h2>
        </router-link>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <router-link
          :to="'/cases/' + actionObj.CaseID + '/detail/ACTIONS'"
        >
          <h3>Case {{ actionObj.CaseFileNumber }}</h3>
        </router-link>
      </b-col>
    </b-row>
    <b-row v-if="notVisible.length >= 1">
      <b-col>
        <b-row>
          <b-col class="text-center">
            <span>One or more video(s) are not visible to client users.</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              :variant="'primary'"
              class="mr-1"
              @click.prevent="makeVisibleAll()"
            >
              Make Visible to All
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="pt-3">
      <b-col>
        <div>{{ TotalVideos }} Videos Total Duration: {{ TotalDuration | minutesFormat }}</div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="video-list">
        <b-row
          v-for="(item, index) of Videos"
          :key="index"
          class="d-flex align-items-center"
          :class="{'active-video': item.VideoID === SelectedVideo.VideoID, 'not-visible': !item.IsPublished}"
        >
          <b-col md="5">
            <b-row class="pt-1 border-top">
              <b-col md="8">
                <div
                  class="video-content cursor-pointer"
                  @click="SelectedVideo = item"
                >
                  <img :src="'https://api.nimbushd.com/Imaging/Thumbnail.ashx?id=' + item.VideoID">
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  :variant="'outline-secondary'"
                  class="mr-1 capture-btn"
                  @click.prevent="showDetails(item)"
                >
                  Video by Frames
                </b-button>
              </b-col>
              <b-col
                md="4"
                class="pl-2"
              >
                <div class="text-nowrap">
                  {{ item.Title }}
                </div>
                <div class="text-nowrap">
                  {{ item.DurationMs | minutesFormat }}
                </div>
                <div v-if="getUserType !== 'Client'">
                  <div class="text-nowrap">
                    Uploaded by {{ item.UploadedBy }}
                  </div>
                  <div class="text-nowrap">
                    on {{ item.CreatedDate | dateFormat }}
                  </div>
                  <div
                    class="text-nowrap btn-link cursor-pointer"
                    @click="changeVisible(item)"
                  >
                    {{ item.IsPublished ? "Hide" : "Make Visible" }}
                  </div>
                </div>
                <div class="download-icon-wrapper">
                  <feather-icon
                    icon="DownloadIcon"
                    class="ml-2 cursor-pointer font-medium-2"
                    @click="downloadVideo(item.VideoID)"
                  />
                </div>
              </b-col>

            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        v-if="SelectedVideo"
        md="7"
        style="max-height: 22rem;"
      >
        <b-row>
          <b-col>
            <div class="text-wrapper">
              If video is in an archived state, you can request the video to be reactivated for playback or download.
              Send requests to: <span class="email-wrapper">
                  <a :href="mailtoMsg">
                    {{ videoManagerEmail }}
                  </a>
                </span>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2 class="text-nowrap">{{ SelectedVideo.Title }}
              <feather-icon
                icon="DownloadIcon"
                class="ml-2 cursor-pointer font-medium-2"
                @click="downloadVideo(SelectedVideo.VideoID)"
              />
            </h2>
          </b-col>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              :variant="'outline-secondary'"
              class="mr-1 capture-btn"
              @click.prevent="captureFrameImage()"
            >
              Capture Frame Image(s)
            </b-button>
          </b-col>
        </b-row>
        <iframe
          v-if="SelectedVideo.EmbedCode"
          id="videoPlayer"
          :src="'/api/file/get-video-script/' + SelectedVideo.EmbedCode + '&w=640&h=400'"
        />
      </b-col>
    </b-row>
    <VideoCapture
      v-if="CaptureFrame"
      :video="SelectedVideo"
      @close="CaptureFrame = false"
    />
  </div>
</template>

<script>

import APIService from "@core/utils/APIService";
import {BButton, BCol, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import VideoCapture from "@/views/cases/components/VideoCapture";
import {mapGetters, mapMutations} from "vuex";
import axios from "axios";
import { saveAs } from 'file-saver';
import {videoManagerEmail} from "@/config/config";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const axiosIns = axios.create({
  responseType: 'blob',
  headers: {
    'Content-Type': 'video/mp4',
  },
})

const apiService = new APIService();

export default {
  components: {
    VideoCapture,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  filters: {
    IsHidden(val) {
      return val ? "Visible" : "Hidden"
    },
    dateFormat(val) {
      const date = new Date(val);
      const options = {year: 'numeric', month: '2-digit', day: '2-digit'}
      const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
      const Time = date.toLocaleTimeString("en-US", timeOptions);
      if (date) return date.toLocaleDateString("en-US", options) + " " + Time;
    },
    minutesFormat(val) {
      const minutes = Math.floor(val / 1000 / 60);
      const seconds = Math.floor(val / 1000 ) - minutes * 60;
      const minutesTxt = minutes === 1 ? " minute " : " minutes "
      const secondsTxt = minutes === 1 ? " second " : " seconds "
      return minutes + minutesTxt + seconds + secondsTxt;
    },
  },
  data() {
    return {
      ActionID: "",
      ActivityID: "",
      actionObj: {},
      notVisible: [],
      Videos: [],
      SelectedVideo: {},
      stopVideo: false,

      TotalVideos: 0,
      TotalDuration: 0,
      CaptureFrame: false,
      videoManagerEmail: videoManagerEmail,
      mailtoMsg: ""
    }
  },
  computed: {
    ...mapGetters({
      getUserType: "auth/getUserType",
      getCurrentScopes: "scopes/getCurrentScopes",
    }),
  },
  watch: {
    SelectedVideo(val) {
      if (val) {
        setTimeout(() => {
          this.nextVideo(500)
        }, 1500)
      }
    }
  },
  created() {
    this.ActionID = this.$route.params.actionID;
    this.ActivityID = this.$route.params.activityID;
    if (this.ActionID) {
      this.loadAction();
      this.loadVideo();
    }
    if (this.ActivityID) {
      this.loadVideo(true);
    }
  },
  methods: {
    ...mapMutations({
      setVideoTime: "video/setVideoTime"
    }),

    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Text copied to clipboard',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },

    loadAction() {
      apiService
          .get("action/" + this.ActionID + "/detail")
          .then(response => {
            this.actionObj = response.data;
            this.mailtoMsg = "mailto:" + this.videoManagerEmail + "?subject=Request to Wake Video for Case " +
                response.data.CaseFileNumber + "&body=Please reactivate the video for streaming on this claim for the following dates:"
          })
    },
    loadVideo(activity = false) {
      const patch = activity ? ("activity/" + this.ActivityID) : ("action/" + this.ActionID)
      apiService
          .get(patch + "/videos")
          .then(res => {
            this.TotalDuration = 0;
            if (this.getUserType === "Client") {
              this.Videos = res.data.filter(i => i.IsPublished === true);
            } else {
              this.Videos = res.data;
              this.notVisible = res.data.filter(i => i.IsPublished === false);
            }
            this.Videos = this.Videos.map((i, index) => { return {...i, index}})
            for (const i in this.Videos) {
              this.TotalDuration = this.TotalDuration + this.Videos[i].DurationMs;
            }
            this.TotalVideos = this.Videos.length;
            this.SelectedVideo = this.Videos[0];
            if (activity) {
              apiService
                  .get("activity/" + this.ActivityID + "/detail")
                  .then(ressult => {
                    this.ActionID = ressult.data.ActionID
                    this.loadAction();
                  })
            }
          })
    },

    captureFrameImage() {
      const vid = document.getElementById("videoPlayer").contentDocument
      const controlTime = vid.querySelector("div.jw-text-elapsed").textContent
      this.setVideoTime(controlTime)
      this.CaptureFrame = true;
      localStorage.setItem('videoTime', this.SelectedVideo.DurationMs)
    },

    nextVideo(time) {
      setTimeout(() => {
        const vid = document.getElementById("videoPlayer").contentDocument;
        const elapsed = vid.querySelector("div.jw-text-elapsed").textContent;
        const duration = vid.querySelector("div.jw-text-duration").textContent;
        const positionElapsed = Number(elapsed.split(":")[0]) * 60 + Number(elapsed.split(":")[1]);
        const positionDuration = Number(duration.split(":")[0]) * 60 + Number(duration.split(":")[1]);
        if (positionElapsed !== positionDuration) this.stopVideo = false
        if (positionElapsed === positionDuration && positionDuration !== 0 && !this.stopVideo) {
          const index = this.SelectedVideo.index + 1 >= this.Videos.length ? this.SelectedVideo.index : this.SelectedVideo.index + 1;
          if (index === this.SelectedVideo.index) return;
          this.stopVideo = true;
          this.SelectedVideo = this.Videos[index]
        } else {
          this.nextVideo(500)
        }
      }, time)
    },

    showDetails(item) {
      localStorage.setItem('videoTime', item.DurationMs)
      localStorage.setItem('activityID', this.ActivityID)
      window.open('/cases/activity/video/' + item.VideoID + '/' + this.ActivityID  + '/' + item.DurationMs, '_blank');
    },

    makeVisibleAll() {
      apiService
          .post("action/" + this.ActionID + "/videos/publish-all")
          .then(() => {
            this.loadVideo(!!this.ActivityID);
          })
    },

    changeVisible(item) {
      const postData = {
        "Publish": !item.IsPublished
      }
      apiService
          .post("video/" + item.VideoID + "/change-publish-state", postData)
          .then(() => {
            this.loadVideo(!!this.ActivityID);
          })
    },

    downloadVideo(VideoID) {
      axiosIns.get("/api/video/" + VideoID + "/download")
          .then(response => {
            saveAs(new File([response.data], "", {type: 'video/mp4'}))
          })
    },
  },
}
</script>

<style scoped>
#videoPlayer {
  width: 100%;
  height: 100%;
  min-height: 30rem;
}
.video-content img {
  width: inherit;
  height: inherit;
}
.video-content {
  display: inline-block;
  background-color: grey;
  height: 4.5rem;
  width: 8rem;
  position: relative;
  top: -0.5rem;
}
.capture-btn {
margin-bottom: 0.2rem;
}
.video-list {
  max-height: 50rem;
  overflow: auto;
}
.active-video {
  border: 1px solid red;
}
.download-icon-wrapper {
  position: absolute;
  left: 15rem;
  top: 2rem;
}
.not-visible {
  background-color: rgb(234 84 85 / 40%);
}
.text-wrapper {
  width: 80%;
  margin: auto;
  text-align: center;
  padding-bottom: 1rem;
}
.email-wrapper {
  color: blue;
  cursor: pointer;
}
</style>